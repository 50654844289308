<template>
    <header>
        <nav class="navbar navbar-expand-lg navbar-light ">
            <div class="container">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <a class="navbar-brand" href="#">
                    <router-link  class="nav-link mont-700" to="/">
                        <img src="../assets/images/bathle-small.png" alt="" style="max-width:142px;">
                    </router-link>
                </a>
                <div class="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo03">
                    <ul class="navbar-nav d-flex ">
                        <li class="nav-item">
                            <a class="nav-link active mont-700" aria-current="page" href="#">
                                <span><img src="../assets/images/shield.svg"></span>&nbsp;&nbsp;BATHLE PRICE PROMISE
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link mont-700" href="#">ABOUT BATHLE</a>
                        </li>
                        <li class="nav-item">
                            <router-link  class="nav-link mont-700" to="/contactus">CONTACT</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link  class="nav-link mont-700 green-btn" to="/Qualifyingprocess">START QUOTE</router-link>

                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>
<script>
export default{
    name:"header-cmp"
}
</script>