<template>
    <div class="container">
		<h1 class="comforta-700 text-center mt-5">
			Book consultation for £50
		</h1>
		<div class="full-height">
			<div class="noquotbox">
                <router-link class="back-btn mt-5" to="/product">
                        <i class="fas fa-chevron-left"></i> &nbsp;Back
                </router-link>
				<div class="calendar-here-integ">                             
				<DatePicker v-model="date" /></div>
				<a class="detail-continue mb-5" style="max-width: 296px;width: 100%;" v-on:click="ConsultationBook" to="/payment">CONTINUE</a>
			</div>
		</div>
	</div>
</template>

<script>
import bathleApiService  from '@/core/services/bathleApiService.js';
import {Consultation}  from '@/core/model/bathleModels.js'

import {  DatePicker } from 'v-calendar';
export default{
    name:"ConsultationSurvey",
	data(){
		return{
        quoteId:null,
		}
	},
	components: {
    DatePicker,
  },
  created:function()
    {
          this.LoadInitialInfo()
    },
    methods:{
		LoadInitialInfo:async function()
        {
           this.quoteId=await localStorage.getItem(process.env.VUE_APP_QUOTE_ID);
           let response= await bathleApiService.GetQuoteById(this.quoteId);
           if(response.status==200)
           {
			console.log(response.data.quote_price);
           }
        },

		ConsultationBook:async function()
		{
           let consultation=new Consultation();
			consultation.quote_id=this.quoteId;
			consultation.booking_date=this.formatDate(this.date);
			consultation.booking_amount="50";
			let response= await bathleApiService.consultation({consultation:consultation});
        if(response.status==200)
        {
        let responseStatus= await bathleApiService.updateQuoteStatus({quote:{quote_id:this.quoteId,status:3}})
        if(responseStatus.status==200){
				this.$router.push('/payment') 
        }
				//console.log(response.data.quote_price);
      }
		},
		formatDate:  function (date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
        month = '0' + month;
        if (day.length < 2) 
         day = '0' + day;

       return [year, month, day].join('/');
      }
    }

}
</script>
