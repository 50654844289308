

<template>
	<h1>QuoteNotAvailable</h1>
</template>

<script>
export default{
    name:"quotenot-available",
    methods:{
    }

}
</script>
