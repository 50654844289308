import { createApp } from 'vue'
import App from './App.vue'
import Home from './components/Home.component.vue'
import ContactUs from './components/ContactUs.component.vue';
import Qualifyingquestions from './components/QualifyDynamicComponent.vue'
import YourDetails from './components/YourDetails.component.vue' 
import Products from './components/Product.component.vue'
import FullyBooked from './components/FullyBooked.component.vue'
import Payment from './components/Payment.component.vue'
import ConsultationSurvey from './components/Consultation.component.vue'
import Reminder from './components/Reminder.component.vue'
import Thankyou from './components/Thankyou.component.vue'
import Quotsent from './components/Quotsent.component.vue'
import QuoteNotAvailable from './components/QuoteNotAvailable.component.vue'
import InstallationDate from './components/InstallationDate.component.vue'
import VueCryptojs from 'vue-cryptojs'


import {createRouter, createWebHistory} from "vue-router";


  const routes= [
      {path:'/',component:Home, meta:{title: 'Home'}},
    { path: '/contactus', component: ContactUs, alias: '/b', meta:{title: 'Contact'}},
    {path:'/Qualifyingprocess',component:Qualifyingquestions, meta:{title: 'Home'}},
    {path:'/yourdetails',component:YourDetails,      props: true,meta:{title: 'Details'}},
    {path:'/product',component:Products, meta:{title: 'product'}},
    {path:'/payment',component:Payment, meta:{title: 'payment'}},
    {path:'/fullybooked',component:FullyBooked, meta:{title: 'fullybooked'}},
    {path:'/consultation',component:ConsultationSurvey, meta:{title: 'consultation'}},
    {path:'/reminder',component:Reminder, meta:{title: 'reminder'}},
    {path:'/thankyou',component:Thankyou, meta:{title: 'thankyou'}},
    {path:'/quotsent',component:Quotsent, meta:{title: 'quotesent'}},
    {path:'/quotnotavailable',component:QuoteNotAvailable, meta:{title: 'quotnotavailable'}},
    {path:'/bookdate',component:InstallationDate, meta:{title: 'bookdate'}}

  
  ]
  
  

const router =  createRouter({
  history: createWebHistory(),
  routes:routes
 
})
createApp(App).use(router).use(VueCryptojs).mount('#app')
