<template>
	<div class="container">
		<div class="full-height">
			<div class="noquotbox">
				<img src="../assets/images/spanner.svg" class="py-5">
				<h2 class="notinyourarea-h2">Your local fitter is currently fully booked</h2>
				<p class="p my-5">
					Sorry, all local fitters in your area are currently fully <br>booked. Don’t worry, if you would like we can email or <br>call you to let you know when we have availability again.
				</p>
				<p v-if="errors.length" style="color:red">
                        <b>Please correct the following error(s):</b>
                                <ul>
                                <li v-for="error in errors" v-bind:key="error.id">{{ error }}</li>
                                </ul>
                </p>
				<div class="second-box">
					<div class="row">
						<div class="mb-3 col-md">
							<label for="exampleInputEmail1" class="form-label">Email Address</label>
							<input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model="userEmail" placeholder="Enter...">
						</div>
						<div class="mb-3 col-md">
							<label for="reminderdate" class="form-label">Phone Number</label>
							<input type="text" class="form-control" id="reminterdate" aria-describedby="emailHelp" v-model="phoneNumber" placeholder="Enter...">
						</div>
						<div class="col-12">
							<button class="detail-continue" v-on:click="submitFullyBooked">NOTIFY ME</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import bathleApiService  from '@/core/services/bathleApiService.js'

export default{
    name:"FullyBooked",
	data(){
        return {
            errors:[],          
            userEmail:null,
            phoneNumber:null
        }
    },
   methods:{
        submitFullyBooked: async function(){
             this.errors=[];           
             if (!this.userEmail) {
               this.errors.push('Email required.');
              }
              if (!this.phoneNumber) {
               this.errors.push('Phone Number required.');
              }
               let respose= await bathleApiService.notify({notify:{email:this.userEmail,phone:this.phoneNumber}});
               if(respose.status==200)
               {
                alert("Notified");
                this.$router.push('/') 
               }


		},
		formatDate:  function (date) {
           var d = new Date(date),
           month = '' + (d.getMonth() + 1),
           day = '' + d.getDate(),
           year = d.getFullYear();

       if (month.length < 2) 
         month = '0' + month;
       if (day.length < 2) 
         day = '0' + day;

      return [year, month, day].join('/');
   }
	}

}
</script>
