export class Quote
{
    quote_id;
    supplier_id;
    purchased_bath_suite;
    where_did_you_buy;
    looking_for_installation_12_weeks;
    is_installation_budget_between;
    booking_date;
    name;
    email;
    phone;
    is_quote_accepted;
    status;
}

export class QuoteItem
{
    quote_id;
    product_id;
    product_price;
	product_qty;
	product_info;
}

export class EmailReminder
{
    email;
	reminder_date;
}

export class Notify
{
    email;
    phone;
}

export class Consultation
{
    quote_id;
    booking_amount;
    booking_date;
}

export class Payment
{
    total_amount
	type;
	payment_status;
	purchase_id;
    quote_id;
	payment_info;
	transaction_id;
}

