<template>
    <footer>
        <div class="container">
            <div class="col-12">
                <div class="footer-logo">
                    <img src="../assets/images/bathlelogo.png" alt="">
                </div>
                <div class="footer-links">
                    <a class="nav-link active mont-700" aria-current="page" href="#">
                        <span><img src="../assets/images/shield.svg"></span>&nbsp;&nbsp;BATHLE PRICE PROMISE
                    </a>
                    <a class="nav-link mont-700" href="#">ABOUT BATHLE</a>
                    <router-link  class="nav-link mont-700" to="/contactus">CONTACT</router-link>
                    <router-link  class="nav-link mont-700 green-btn" to="/Qualifyingprocess">START QUOTE</router-link>

                </div>
                <div class="footer-social">
                    <img src="../assets/images/instagram.svg" alt="">
                    <img src="../assets/images/facebook.svg" alt="">
                    <img src="../assets/images/linkedin.svg" alt="">
                    <img src="../assets/images/twitter.svg" alt="">
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default{
    name:"footer-cmp"
}
</script>