<template>
        <section class="contact-container">
        <div class="container">
            <h1 class="header text-center">
                Contact us
            </h1>
            <h2 class="secondary-text">
                We'd love to hear from you
            </h2>
            <p class="third-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed<br>
                do eiusmod tempor incididunt ut labore et dolore magna
            </p>
            <ul class="contact-info">
                <li><span><img src="../assets/images/address.svg"> </span> 385 Noah Place Suite 878</li>
                <li><span><img src="../assets/images/phone.svg"> </span> 877-255-7945</li>
                <li><span><img src="../assets/images/envelope.svg"> </span> email@bathle.com</li>
            </ul>
            <div class="form-box">
                <form>
                    <div class="mb-5">
                        <label for="exampleInputName1" class="form-label">Name</label>
                        <input type="text" class="form-control" id="exampleInputName1" aria-describedby="emailHelp" placeholder="Enter...">
                    </div>
                    <div class="mb-5">
                        <label for="exampleInputEmail1" class="form-label">Email Address</label>
                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter...">
                    </div>
                    <div class="mb-5">
                        <label for="exampleFormControlTextarea1" class="form-label">Message</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder="Enter..."></textarea>
                    </div>
                    <button type="submit">SEND</button>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
export default{
  name:"ContactUs",

  beforeMount() {
      //alert("dd")
    window.addEventListener("beforeunload", event => {
      event.preventDefault()
      // Chrome requires returnValue to be set.
      event.returnValue = ""
      alert("dd")
      
    })
  }
}
</script>

