import { api } from "../services/api.js";

export default  {
  Quote(quote) {
    console.log(JSON.stringify(quote))
    return api("quote", {
      method: "POST",
      body: JSON.stringify({quote:quote})
    });
  },

  UpdateQuote(quote) {
    console.log(JSON.stringify(quote))
    return api("quote", {
      method: "POST",
      body: JSON.stringify({quote:quote})
    });
  },


  quoteItems(quoteItems) {
    return api("quote_items", {
      method: "POST",
      body: JSON.stringify(quoteItems)
    });
  },

  reminder(reminder) {
    return api("reminder", {
      method: "POST",
      body: JSON.stringify(reminder)
    });
  },

  notify(notify) {
    return api("notify", {
      method: "POST",
      body: JSON.stringify(notify)
    });
  },

  consultation(consultation) {
    console.log(JSON.stringify(consultation));
    return api("consultation", {
      method: "POST",
      body: JSON.stringify(consultation)
    });
  },

  payment(payment) {
    console.log(JSON.stringify(payment))
    return api("payment", {
      method: "POST",
      body: JSON.stringify(payment)
    });
  },
  

  ProductSearch(searchkey,supplier_id) {
    return api("search?name="+searchkey+ "&supplier_id="+supplier_id,{ method: "GET"});
  },

  GetQuoteItems(quote_id) {
    return api("getQuoteItem?quote_id="+quote_id,{ method: "GET"});
  },

  RemoveQuoteItem(quote_id,product_id) {
    return api("removeItem?quote_id="+quote_id+"&product_id="+product_id,{ method: "GET"});
  },

  GetSupplierById(supplier_id) {
    return api("supplierInfo?supplier_id="+supplier_id,{ method: "GET"});
  },

  GetQuoteById(quote_id) {
    return api("getQuoteById?quote_id="+quote_id,{ method: "GET"});
  },

  ProductQtyUpdate(quote_id,productId,type) {
    return api("qtyUpdate?quote_id="+quote_id+"&product_id="+productId+"&type="+type,{ method: "GET"});
  },

  updateQuoteStatus(val) {
    return api("updateQuoteStatus", {
      method: "POST",
      body: JSON.stringify(val)
    });
  },

  updateQuoteAccept(val) {
    return api("updateQuoteAccept", {
      method: "POST",
      body: JSON.stringify(val)
    });
  },
  updateQuotePrice(val) {
    return api("updateQuotePrice", {
      method: "POST",
      body: JSON.stringify(val)
    });
  },
  updateQuoteBookingDate(val) {
    return api("updateQuoteBookingDate", {
      method: "POST",
      body: JSON.stringify(val)
    });
  },
  AddFeedback(val) {
    return api("feedback", {
      method: "POST",
      body: JSON.stringify(val)
    });
  }



};