<template>
<div v-if="tempateId==1">
        <div class="container-fluid my-5">
        <div class="row">
            <div class="col-12">
                <div class="full-height">
                    <div class="div-contentt">
                        <div class="line-progress" style="width: 96%;">
                            <div class="dot-n-text">
                                <div class="dot active"></div>
                            </div>
                            <div class="line active">
                                <div></div>
                            </div>
                            <div class="dot-n-text">
                                <div class="dot "></div>
                            </div>
                            <div class="line">
                                <div></div>
                            </div>
                            <div class="dot-n-text">
                                <div class="dot disable"></div>
                            </div>
                        </div>
                        <div class="line-progress">
                            <p class="text-u-dot mont-600">Details</p>
                            <p class="text-u-dot mont-600">Products</p>
                            <p class="text-u-dot mont-600">Quote</p>
                        </div>
                        <h2 class="header text-center">Search for product(s)</h2>
                        <div class="detail-boxex">
                            <img :src="SupplierLogoUrl" alt="" height="70" width="70">
                            <p class="we-use-this-to-assig">{{supplierName}}</p>
                            <div class="input-divi">
                                <input type="text" placeholder="Search for SKU or product name" v-model="searchkey">
                            </div>
                            <div class="input-divi">
                                <button class="detail-continue" v-on:click="AddProduct">SEARCH FOR PRODUCT</button>
                            </div>
                        </div>
                        <div class="prefer-survery">
                            <div class="div-11">
                                <p class="prefer-text">Prefer a consultation survey?</p>
                                <span>We can talk through your options</span>
                                <router-link to="/consultation">
                                    Book consultation
                                </router-link>
                            </div>
                            <div class="image-div-2">
                                <img src="../assets/images/consultation-image.svg" alt="">
                            </div>
                            <div class="circl-price-box">
                                <span>Just</span>
                                <span>£100</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>
<div v-if="tempateId==2">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="full-height">
                    <div class="div-contentt">
                        <div class="line-progress" style="width: 96%;">
                            <div class="dot-n-text">
                                <div class="dot active"></div>
                            </div>
                            <div class="line active">
                                <div></div>
                            </div>
                            <div class="dot-n-text">
                                <div class="dot"></div>
                            </div>
                            <div class="line">
                                <div></div>
                            </div>
                            <div class="dot-n-text">
                                <div class="dot disable"></div>
                            </div>
                        </div>
                        <div class="line-progress">
                            <p class="text-u-dot mont-600">Details</p>
                            <p class="text-u-dot mont-600">Products</p>
                            <p class="text-u-dot mont-600">Quote</p>
                        </div>
                        <h2 class="header text-center">Search for product(s)</h2>
                        <div class="detail-boxex">
                            <div class="input-divi ff-width">
                                <input type="text" class="ff-width" placeholder="Search" v-model="searchkey" v-on:keyup="EnterSearch">
                            </div>
                            <div v-for="item in products" v-bind:key="item.id" class="product-luistt ff-width">
                                <div class="product-n-image">
                                    <img src="../assets/images/consultation-image.svg" alt="">
                                    <div class="product-nmggg">
                                        <p>{{item.product_title}}</p>
                                        <span> {{item.product_code}}</span>
                                    </div>
                                </div>
                                <a class="add-btnne green-btn" v-on:click="AddToCart(item)">&nbsp;ADD&nbsp;</a>
                            </div>
                        </div>
                        <div class="prefer-survery">
                            <div class="div-11">
                                <p class="prefer-text">Prefer a consultation survey?</p>
                                <span>We can talk through your options</span>
                                <router-link to="/consultation">
                                    Book consultation
                                </router-link>
                            </div>
                            <div class="image-div-2">
                                <img src="../assets/images/consultation-image.svg" alt="">
                            </div>
                            <div class="circl-price-box">
                                <span>Just</span>
                                <span>£100</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div v-if="tempateId==3">
    <div class="container-fluid my-5">
        <div class="row">
            <div class="col-12">
                <div class="">
                    <div class="div-contentt mx-auto mt-5">
                        <div class="line-progress" style="width: 96%;">
                            <div class="dot-n-text">
                                <div class="dot active"></div>
                            </div>
                            <div class="line active">
                                <div></div>
                            </div>
                            <div class="dot-n-text">
                                <div class="dot "></div>
                            </div>
                            <div class="line">
                                <div></div>
                            </div>
                            <div class="dot-n-text">
                                <div class="dot disable"></div>
                            </div>
                        </div>
                        <div class="line-progress">
                            <p class="text-u-dot mont-600">Details</p>
                            <p class="text-u-dot mont-600">Products</p>
                            <p class="text-u-dot mont-600">Quote</p>
                        </div>
                        <h2 class="header text-center">Search for product(s)</h2>
                    </div>
                    <div class="container">
                        <div class=" row">
                            <div class="table-box col-12 col-md-8">
                                <table class="table-custommm">
                                    <tbody>
                                        <tr>
                                            <th>SKU</th>
                                            <th>Product</th>
                                            <th>Qty</th>
                                            <th>Remove</th>
                                        </tr>
                                        <tr v-for="item in cartItems"  v-bind:key="item.id">
                                            <td>SKU53464</td>
                                            <td><b>{{JSON.parse(item.product_info).product_title}}</b></td>
                                            <td><a><i class="fas fa-minus" v-on:click="RemoveQuantity(item)" ></i></a> &nbsp;<b>{{item.product_qty}}</b> &nbsp;<a><i class="fas fa-plus" v-on:click="AddQuantity(item)"></i></a></td>
                                            <td>
                                                <a v-on:click="RemoveQuoteItem(item)" ><i class="fas fa-trash-alt"></i></a>
                                            </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                            <div class=" col-12 col-md-4">
                                <div class="detail-boxex ">
                                    <p class="add-another-product ">Add another product?</p>
                                    <div class="input-divi">
                                        <input type="text" placeholder="eg BS1 2DA" v-model="searchkey">
                                    </div>
                                    <div class="input-divi">
                                        <button class="detail-continue" type="submit" v-on:click="AddProduct" >ADD PRODUCT</button>
                                    </div>
                                </div>
                                <div class="input-divi mx-auto mt-4" style="max-width: 100%;">
                                    <button class="detail-continue" v-on:click="YourQuote">GET QUOTE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="consultation-block">
        <div class="row">
            <div class="col-sm-8">
                <h3>Prefer a consultation survey?</h3>
                <h4>We can talk through your options. £100 charge.</h4>
                <router-link class="find-out-more" to="/consultation">Book consultation</router-link>
            </div>
            <div class="col-sm-4">
                <div class="image-div-2">
                    <img src="../assets/images/consultation-image.svg" alt="">
                </div>
                <div class="circl-price-box">
                    <span>Just</span>
                    <span>£100</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div v-if="tempateId==4">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="full-height">
                    <div class="div-contentt">
                        <div class="line-progress" style="width: 96%;">
                            <div class="dot-n-text">
                                <div class="dot active"></div>
                            </div>
                            <div class="line active">
                                <div></div>
                            </div>
                            <div class="dot-n-text">
                                <div class="dot active"></div>
                            </div>
                            <div class="line active">
                                <div></div>
                            </div>
                            <div class="dot-n-text">
                                <div class="dot"></div>
                            </div>
                        </div>
                        <div class="line-progress">
                            <p class="text-u-dot mont-600">Details</p>
                            <p class="text-u-dot mont-600">Products</p>
                            <p class="text-u-dot mont-600">Quote</p>
                        </div>
                        <h2 class="header text-center my-5">Your Quote</h2>
                        <div class="detail-boxex">
                        <h2 class="header text-center h640">£{{quoteTotalAmount}}</h2>
                        <span class="quote-amount">Quote Amount</span>
                            <p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <div class="protect-withe">
                                <img src="../assets/images/Shield-small.svg" alt="">
                                <div>
                                    <p class="protected-with-our-p">Protected with our price guarantee </p>
                                    <router-link class="find-out-more" to="/consultation">
                                        Find out more &nbsp;<i class="fas fa-chevron-right"></i>
                                    </router-link>
                                </div>
                            </div>
                        <div class="li-ul-li">
                            <ul>
                                <li><img src="../assets/images/icons/tick.svg" alt=""> <span>Bathle approved & accredited</span></li>
                                <li><img src="../assets/images/icons/tick.svg" alt=""> <span>Combined 400 years experience</span></li>
                                <li><img src="../assets/images/icons/tick.svg" alt=""> <span>Friendly locals</span></li>
                            </ul>
                        </div>
                            <div class="input-divvision">
                                <a class="detail-continue2 green" v-on:click="AcceptQuote()">
                                    Accept quote
                                </a>
                                <a class="detail-continue2 red" v-on:click="DeclineQuote()" >
                                    decline quote
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import bathleApiService  from '@/core/services/bathleApiService.js';
import {QuoteItem}  from '@/core/model/bathleModels.js'


export default{
    name:"product-cmp",
    data(){
        return {
        tempateId:1,
        SupplierLogoUrl:null,
        supplierName:null,
        quoteId:null,
        searchkey:null,
        supplier_id:null,
        products:[],
        cartItems:[],
        quoteTotalAmount:null
        }
    },
    created:function()
    {
          this.LoadSupplierInfo()
    },
    methods:{
        LoadSupplierInfo:async function()
        {
           this.quoteId=await localStorage.getItem(process.env.VUE_APP_QUOTE_ID);
           if(this.quoteId==null)
           {
              this.$router.push('/Qualifyingprocess') 
           }
           let response= await bathleApiService.GetQuoteById(this.quoteId);

           if(response.status==200)
           {
               this.supplier_id=response.data.supplier_id;
               this.SupplierLogoUrl=response.data.logo_url;
               this.supplierName=response.data.supplier_name;
           }
           
        },

        Search:function()
        {
          this.tempateId=1;
        },

        AddProduct:async function()
        {
          let response=await bathleApiService.ProductSearch(this.searchkey,this.supplier_id);
         
         if(response.status==200)
          {
              this.products=response.products;
              
          }  
          this.tempateId=2;
        },

        EnterSearch:async function(e)
        {
                if (e.keyCode === 13) {
                    let response=await bathleApiService.ProductSearch(this.searchkey,this.supplier_id);
         
                   if(response.status==200)
                   {
                    
                     this.products=response.products;
              
                   }
                   if(response.status==201)  
                   { 
                       this.products=[];
                   }
                }

        },

        AddToCart:async function(product)
        {
            if(product!=null)
            {
                let items=[];
                let item=new QuoteItem();
                item.quote_id=this.quoteId;
                item.product_id=product.product_id;
                item.product_price=product.installation_cost;
                item.product_qty=1;
                item.product_info=JSON.stringify(product);
                items.push(item);
                console.log(items)

                 let response= await bathleApiService.quoteItems({quote_items:items});
                 console.log(response);
                 if(response.status==200)
                 {
                     this.cartItems=response.quote_items;
                     this.quoteTotalAmount= response.total_amount;
                     this.tempateId=3;
                 }
            }
            
        },

        YourQuote:async function()
        {
                 let response= await bathleApiService.updateQuotePrice({quote:{quote_id:this.quoteId,quote_price:this.quoteTotalAmount}})
                 if(response.status==200)
                 {                    
                     this.quoteTotalAmount= response.data.quote_price;
                     this.tempateId=4;
                 }
        },

        RemoveQuantity: async function(item)
        {
              let response= await bathleApiService.ProductQtyUpdate(item.quote_id,item.product_id,2)
              if(response.status==200)
                 {
                     this.cartItems=response.quote_items;
                     this.quoteTotalAmount= response.total_amount;

                 }
        },

        AddQuantity:async function(item)
        {
              let response= await bathleApiService.ProductQtyUpdate(item.quote_id,item.product_id,1)
                 if(response.status==200)
                 {
                     this.cartItems=response.quote_items;
                     this.quoteTotalAmount= response.total_amount;
                 }

        },

        RemoveQuoteItem:async function(item)
        {
              let response= await bathleApiService.RemoveQuoteItem(item.quote_id,item.product_id)
                 if(response.status==200)
                 {
                     this.cartItems=response.quote_items;
                     this.quoteTotalAmount= response.total_amount;
                 }

        },

        AcceptQuote:async function()
        {
                 let response= await bathleApiService.updateQuoteAccept({quote:{quote_id:this.quoteId,is_quote_accepted:1}})
                 if(response.status==200)
                 {                    
                     this.quoteTotalAmount= response.data.quote_price;
                     this.$router.push('/bookdate') 
                 }
        },

        DeclineQuote:async function()
        {
                 let response= await bathleApiService.updateQuoteAccept({quote:{quote_id:this.quoteId,is_quote_accepted:2}})
                 if(response.status==200)
                 {                    
                     this.quoteTotalAmount= response.data.quote_price;
                     this.$router.push('/thankyou') 

                 }
        }

        

    }
}
</script>
