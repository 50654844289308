<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="full-height">
                    <div class="div-contentt">
                        <div class="line-progress" style="width: 96%;">
                            <div class="dot-n-text">
                                <div class="dot active"></div>
                            </div>
                            <div class="line active">
                                <div></div>
                            </div>
                            <div class="dot-n-text">
                                <div class="dot active"></div>
                            </div>
                            <div class="line active">
                                <div></div>
                            </div>
                            <div class="dot-n-text">
                                <div class="dot"></div>
                            </div>
                        </div>

                        <div class="line-progress">
                            <p class="text-u-dot mont-600">Details</p>
                            <p class="text-u-dot mont-600">Product</p>
                            <p class="text-u-dot mont-600">Quote</p>
                        </div>

                        <h2 class="header text-center">Quote Sent</h2>
                        <div class="detail-boxex">
                            <img src="../assets/images/email-sent.svg" class="py-5">
                            <h3 class="post-title my-4">
                                Please check your email
                            </h3>
                            <p class="p">
                                We have sent the quote to your email address. This can take up <br>to 15 minutes. Please check your inbox and junk mail.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    name:"quote-sent"
}
</script>
