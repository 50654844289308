<template>
    <div class="container">
		<div class="full-height">
			<div class="detail-boxex">
				<h2 class="hh2 my-5">Thank you</h2>
				<p class="p my-5">
					Your quote has been saved, so if you change your mind later please just let us know.
				</p>
				<h3 class="hh3">
					Can you spare a few seconds?
				</h3>
				<p class="bold-p my-5">
					What was the reason(s) you declined the offer?
				</p>
				<p v-if="errors.length" style="color:red">
                        <b>Please correct the following error(s):</b>
                                <ul>
                                <li v-for="error in errors" v-bind:key="error.id">{{ error }}</li>
                                </ul>
                </p>
				<div class="row">
					<div class="mb-3 col-md">
						<div class="mb-3 form-check">
							<input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="TooExpensive">
							<label class="form-check-label" for="exampleCheck1">Too expensive</label>
						</div>
						<div class="mb-3 form-check">
							<input type="checkbox" class="form-check-input" id="exampleCheck2" v-model="NoTimesSuitable">
							<label class="form-check-label" for="exampleCheck2" >No times suitable</label>
						</div>
						<div class="mb-3 form-check">
							<input type="checkbox" class="form-check-input" id="exampleCheck3" v-model="OtherReason">
							<label class="form-check-label" for="exampleCheck3">Other reason</label>
						</div>
					</div>
					<div class="col-12">
						<p class="bold-p my-4">Any additional comments?</p>
						<div class="mb-3">
                            <textarea class="form-control" id="exampleFormControlTextarea1" v-model="AdditionalComment" rows="3" placeholder="Anything else we could have done better etc?"></textarea>
                        </div>
					</div>
					<div class="col-12">
						<button class="detail-continue" v-on:click="submitFeedback" >SEND FEEDBACK</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import bathleApiService  from '@/core/services/bathleApiService.js'
export default {
    name:"thankyou-cmp",
	data(){
        return {
            errors:[],          
            TooExpensive:null,
            NoTimesSuitable:null,
			OtherReason:null,
			AdditionalComment:null,
            quoteId:null
        }
    },
	created:function(){
      this.LoadSupplierInfo()
    },
   methods:{
	LoadSupplierInfo:async function()
        {
           const quoteId=await localStorage.getItem(process.env.VUE_APP_QUOTE_ID);
		console.log(quoteId)

		if(quoteId==null || quoteId=="")
           {
              this.$router.push('/Qualifyingprocess') 
           }
           let response= await bathleApiService.GetQuoteById(quoteId);
           if(response.status==200)
           {
               this.quoteId=quoteId;
           }
        },
        submitFeedback: async function(){
             this.errors=[];    		    
				if(this.tooExpensive==null && this.NoTimesSuitable==null && this.OtherReason==null && this.AdditionalComment==null )
				{
					this.errors.push('User input required.');
					return;
				}

           
               let respose= await bathleApiService.AddFeedback(
				{feedback:
				{quote_id:this.quoteId,
				tooExpensive:this.TooExpensive,
				noTimesSuitable:this.NoTimesSuitable,
				otherReason:this.OtherReason,
				comment:this.AdditionalComment
				}});
               if(respose.status==200)
               {
				localStorage.removeItem( process.env.VUE_APP_QUOTE_ID);
				localStorage.removeItem(process.env.VUE_APP_SUPPLIER_KEY);
                this.$router.push('/quotsent') 
               }


		}
}
}
</script>
