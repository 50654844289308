<template>
    <div class="container">
		<div class="full-height">
			<div class="noquotbox">
				<img src="../assets/images/spanner.svg" class="">
				<h2 class="hh2 py-5">We cannot give you a quote <br>at the moment</h2>
				<p class="p my-5">
					You need to have bought a kitchen and looking for an <br>installation within the next 12 weeks.
				</p>
				<div class="second-box">
					<h3 class="hh3">
						Do you want a reminder?
					</h3>
					<p v-if="errors.length" style="color:red">
                        <b>Please correct the following error(s):</b>
                                <ul>
                                <li v-for="error in errors" v-bind:key="error.id">{{ error }}</li>
                                </ul>
                </p>
					<div class="row">
                        <div class="mb-3 col-md">
                            <label for="exampleInputEmail1" class="form-label">Email Address</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model="email" placeholder="Enter...">
                        </div>
						<div class="mb-3 col-md">
                            <label for="reminderdate" class="form-label">Reminder date</label>
                            <input type="date" class="form-control" id="reminderdate" aria-describedby="emailHelp" v-model="ReminderDate" placeholder="DD-MM-YYYY">
                        </div>
						<div class="col-12">
                            <button class="detail-continue" v-on:click="submitReminder" >NOTIFY ME</button>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import bathleApiService  from '@/core/services/bathleApiService.js'

export default {
    name:"Reminder-cmp",
	data(){
        return {
            errors:[],          
            email:null,
            ReminderDate:null
        }
    },
	methods:{
        submitReminder: async function(){
             this.errors=[];           
             if (!this.email) {
               this.errors.push('Email required.');
              }
              if (!this.ReminderDate) {
               this.errors.push('Reminder Date required.');
              }
               let respose= await bathleApiService.reminder({reminder:{email:this.email,reminder_date:this.ReminderDate}});
               if(respose.status==200)
               {
                localStorage.removeItem( process.env.VUE_APP_QUOTE_ID);
				localStorage.removeItem(process.env.VUE_APP_SUPPLIER_KEY);
                alert("Successful");
                this.$router.push('/') 
               }


		},
		formatDate:  function (date) {
           var d = new Date(date),
           month = '' + (d.getMonth() + 1),
           day = '' + d.getDate(),
           year = d.getFullYear();

       if (month.length < 2) 
         month = '0' + month;
       if (day.length < 2) 
         day = '0' + day;

      return [year, month, day].join('/');
   }
	}
}
</script>
