<template>
  <Header/>
  <router-view/>
  <Footer/>
</template>

<script>
import Header from './components/Header.component.vue'
import Footer from './components/Footer.component.vue'


export default {
  name: 'App',
  components: {
    Header,
    Footer  
    }
    
}
</script>

<style>
@import 'assets/style.css';
body{
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
