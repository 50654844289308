<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="full-height">
                    <div class="div-contentt">
                        <div class="line-progress" style="width: 96%;">
                            <div class="dot-n-text">
                                <div class="dot"></div>
                            </div>
                            <div class="line">
                                <div></div>
                            </div>
                            <div class="dot-n-text">
                                <div class="dot disable"></div>
                            </div>
                            <div class="line">
                                <div></div>
                            </div>
                            <div class="dot-n-text">
                                <div class="dot disable"></div>
                            </div>
                        </div>
                        <div class="line-progress">
                            <p class="text-u-dot mont-600">Details</p>
                            <p class="text-u-dot mont-600">Products</p>
                            <p class="text-u-dot mont-600">Quote</p>
                        </div>
                        <h2 class="header text-center">Enter your details</h2>
                        <div class="detail-boxex">
                             <p v-if="errors.length">
                             <b>Please correct the following error(s):</b>
                                <ul>
                                <li v-for="error in errors" v-bind:key="error.id">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="input-divi">
                                <label for="">Name</label>
                                <input type="text" placeholder="Enter ..." v-model="name">
                            </div>
                            <div class="input-divi">
                                <label for="">Email Address</label>
                                <input type="text" placeholder="Enter ..." v-model="email">
                            </div>
                            <div class="input-divi">
                                <label for="">Phone Number</label>
                                <input type="text" placeholder="Enter ..." v-model="phoneNumber">
                            </div>
                            <div class="input-divi">
                                    <button class="detail-continue" v-on:click="submitForm"  type="submit">CONTINUE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import bathleApiService  from '@/core/services/bathleApiService.js'
import {Quote}  from '@/core/model/bathleModels.js'
import { inject } from 'vue'


export default{
    name:"your-details",
    setup()
    {
        const cryoptojs = inject('cryptojs');
        return {
      cryoptojs
    }
    },
    data(){
        return {
            errors:[],
            name:null,
            email:null,
            phoneNumber:null,
            supplierEncryp_id:null
        }
    },
    created:function(){
      this.InitialLoad()
    },
    
    methods:{
         InitialLoad:async function()
         {
            this.supplierEncryp_id=localStorage.getItem(process.env.VUE_APP_SUPPLIER_KEY);

         },
         submitForm:async function()
         {
             let supplier_id = this.cryoptojs.AES.decrypt(this.supplierEncryp_id, process.env.VUE_APP_CRYPTO_KEY).toString(this.cryoptojs.enc.Utf8)        
             console.log(supplier_id)
             if(supplier_id==null)
             {
                 return;
             }
             this.errors=[];
             if (!this.name) {
               this.errors.push('Name required.');
              }
             if (!this.email) {
               this.errors.push('Email required.');
              }
              if (!this.phoneNumber) {
               this.errors.push('Phone Number required.');
              }
              if(this.errors.length==0)
              {
                let quote=new Quote();
                quote.supplier_id=supplier_id;
                quote.purchased_bath_suite="1";
                quote.where_did_you_buy=supplier_id;
                quote.looking_for_installation_12_weeks="1";
                quote.is_installation_budget_between="1";

                quote.booking_date=this.formatDate(Date.now());
                quote.email=this.email;
                quote.phone=this.phoneNumber;
                quote.name=this.name;
                quote.is_quote_accepted="0";
                quote.status=0;
                
               let respose= await bathleApiService.Quote(quote);
               if(respose.status==200)
               {
                   localStorage.setItem(process.env.VUE_APP_QUOTE_ID,respose.quote_id)
                   this.$router.push('/product') 
               }
              }
         },

       formatDate:  function (date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

       if (month.length < 2) 
         month = '0' + month;
       if (day.length < 2) 
         day = '0' + day;

      return [year, month, day].join('/');
   }

         
    }
    
}
</script>
