<template>
<div> 
    <div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="full-height">
					<div class="div-contentt">
						<div class="line-progress" style="width: 96%;">
							<div class="dot-n-text">
								<div class="dot active"></div>
							</div>
							<div class="line active">
								<div></div>
							</div>
							<div class="dot-n-text">
								<div class="dot active"></div>
							</div>
							<div class="line active">
								<div></div>
							</div>
							<div class="dot-n-text">
								<div class="dot"></div>
							</div>
						</div>
						<div class="line-progress">
                            <p class="text-u-dot mont-600">Details</p>
                            <p class="text-u-dot mont-600">Product</p>
                            <p class="text-u-dot mont-600">Quote</p>
                        </div>
						<h2 class="header text-center">Your Quote</h2>
						<div class="detail-boxex">
							<div class="price-nn-tick">
								<img src="../assets/images/icons/tick.svg" alt="">
								<div class="price-nn-quote">
									<h2 class="header text-center h640">£{{quoteTotalAmount}}</h2>
									<span class="quote-amount">Quote Amount</span>
								</div>
							</div>
							<p class="please-choose-a-date">Please choose a date at your convenience for the installation. It usually takes around 2-3 hours for a bathroom install.</p>
							<div class="calendar-here-integ">
                             <DatePicker v-model="date" />
							</div>
							<div class="input-divi">
                                <a class="detail-continue2 green" v-on:click="ConfirmDate">continue</a>
							</div>
						</div>
						<div class="prefer-survery">
                            <div class="div-11">
                                <p class="prefer-text">Prefer a consultation survey?</p>
                                <span>We can talk through your options</span>
                                <router-link to="/consultation">
                                    Book consultation
                                </router-link>
                            </div>
                            <div class="image-div-2">
                                <img src="../assets/images/consultation-image.svg" alt="">
                            </div>
                            <div class="circl-price-box">
                                <span>Just</span>
                                <span>£100</span>
                            </div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import bathleApiService  from '@/core/services/bathleApiService.js';
import {  DatePicker } from 'v-calendar';

export default{
    name:"Installation-Date",
	data(){
		return{
        quoteId:null,
        quoteTotalAmount:null
		}
	},
	components: {
    DatePicker,
    },
    created:function()
    {
        this.LoadInitialInfo()
    },
    methods:{
        
        LoadInitialInfo:async function()
        {
           this.quoteId=await localStorage.getItem(process.env.VUE_APP_QUOTE_ID);
           if(this.quoteId==null)
           {
              this.$router.push('/Qualifyingprocess') 
           }
           let response= await bathleApiService.GetQuoteById(this.quoteId);
           if(response.status==200)
           {
			this.quoteTotalAmount= response.data.quote_price;
           }
        },

		ConfirmDate:async function()
        {
			console.log(this.date);
                 let response= await bathleApiService.updateQuoteBookingDate({quote:{quote_id:this.quoteId,booking_date:this.formatDate( this.date),status:"1"}})
                 if(response.status==200)
                 {                    
                     this.quoteTotalAmount= response.data.quote_price;
                    this.$router.push('/payment') 
                 }
        },

		formatDate:  function (date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

       if (month.length < 2) 
         month = '0' + month;
       if (day.length < 2) 
         day = '0' + day;

      return [year, month, day].join('/');
   }

    }

}
</script>
