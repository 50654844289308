<template>
  <div class="container">
    <div class="full-height" style="display:block;">
    <h1 class="comforta-700 text-center my-5">Payment</h1>
    <div class="noquotbox" style="margin: auto;">

        <div class="second-box row">
            <div class="mb-5 col-12">
              <label class="form-label">Card Number</label>
                 <div id="card-number"></div>
            </div>
            <div class="mb-5 col-12">
               <label class="form-label">Card Expiry</label>
               <div id="card-expiry"></div>
            </div>
            <div class="mb-5 col-12">
             <label class="form-label">Card CVC</label>
             <div id="card-cvc"></div>
            </div>
          <div id="card-error"></div>
          <button id="custom-button" @click="createToken" class="detail-continue">CONFIRM BOOKING AND PAY</button>
        </div>
        <div class="safetxt">
        <div>
            <img src="../assets/images/lock.svg" alt="">
            <p>Safe and secure payments.</p>
            </div>
        </div>
     </div>
  </div>
  </div>
</template>

<script>
import bathleApiService  from '@/core/services/bathleApiService.js';

export default {
  name:"a-a",
  data () {
    return {
      stripe:null,
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      stripeValidationError:null,
      quoteTotalAmount:null,
      quoteId1:null,
      quoteStatus:null
    };
  },
  computed: {
    stripeElements () {
      return this.stripe.elements();
    },
  },
  mounted () {
      this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PK)
    // Style Object documentation here: https://stripe.com/docs/js/appendix/style
    const style = {
      base: {
        color: 'black',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };
    this.cardNumber = this.stripeElements.create('cardNumber', { style });
    this.cardNumber.mount('#card-number');
    this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc = this.stripeElements.create('cardCvc', { style });
    this.cardCvc.mount('#card-cvc');


    this.cardNumber.on("change", this.setValidationError);
    this.cardExpiry.on("change", this.setValidationError);
    this.cardCvc.on("change", this.setValidationError);

  },

  created:function()
    {
        this.LoadInitialInfo()
    },
  
  methods: {

     LoadInitialInfo : async function(){
      this.quoteId=await localStorage.getItem(process.env.VUE_APP_QUOTE_ID);
      if(this.quoteId==null)
           {
              this.$router.push('/Qualifyingprocess') 
           }
      let response= await bathleApiService.GetQuoteById(this.quoteId);

      if(response.status == 200){
      this.quoteStatus = response.data.status;
      if(this.quoteStatus==3)
      {
        this.quoteTotalAmount = 50;
      }
      else{
        this.quoteTotalAmount = response.data.quote_price;
      }
      }
      },

    async createToken () {
      const { token, error } = await this.stripe.createToken(this.cardNumber);
      if (error) {
        // handle error here
        document.getElementById('card-error').innerHTML = error.message;
        return;
      }
      console.log(token);
      let response= await bathleApiService.payment({payment:{
       total_amount: this.quoteTotalAmount,
      type: (this.quoteStatus==3)?2:1,
      payment_status: "1",
      quote_id: this.quoteId,
      purchase_id: "00123",
      stripeToken:token.id,
      payment_info:"fcgfg"
	} });

  if(response.status==200)
  {
  alert("Payment Successful");
  
  this.$router.push('/') 
     
  }

  

      // handle the token
      // send it to your server
    },
    setValidationError(event) {
      this.stripeValidationError = event.error ? event.error.message : "";
   },
  }
};
</script>

<style scoped>
#custom-button {
  height: 30px;
  outline: 1px solid grey;
  background-color: green;
  padding: 5px;
  color: white;
}

#card-error {
  color: red;
}
</style>