<template>
    <main>
        <div class="banner">
            <div class="container">
                <div class="banner-text">
                    <h1 class="comforta-700">
                        Bathroom Fitting <br> without the hassle.
                    </h1>
                    <p class="mont-500">Some short tagline her explaning your offer</p>
                    <router-link  class="mont-700 green-btn mt-3" to="/Qualifyingprocess">GET YOUR INSTANT QUOTE</router-link>
                </div>
            </div>
        </div>
        
        <section class="suppliers">
            <div class="container my-5">
                <div class="row">
                    <div class="col-12">
                        <h3 class="text-center suppliers-bathrooms">Supplier's Bathroom we install </h3>
                    </div>
                </div>
                <carousel :settings="settings" :breakpoints="breakpoints" :autoplay="2000" :wrap-around="true">
                    <slide class="">
                        <router-link to="/Qualifyingprocess">
                            <img src="../assets/images/company/victoria-plumbing.png" alt="">
                        </router-link>
                    </slide>
                    <slide class="">
                        <router-link to="/Qualifyingprocess">
                            <img src="../assets/images/company/B&Q.png" alt="">
                        </router-link>
                    </slide>
                    <slide class="">
                        <router-link to="/Qualifyingprocess">
                            <img src="../assets/images/company/Bathroom-village.png" alt="">
                        </router-link>
                    </slide>
                    <slide class="">
                        <router-link to="/Qualifyingprocess">
                            <img src="../assets/images/company/Wickes.png" alt="">
                        </router-link>
                    </slide>
                    <slide class="">
                        <router-link to="/Qualifyingprocess">
                            <img src="../assets/images/company/bathstore.png" alt="">
                        </router-link>
                    </slide>
                    <slide class="">
                        <router-link to="/Qualifyingprocess">
                            <img src="../assets/images/company/victoriaplum.png" alt="">
                        </router-link>
                    </slide>
                    <template #addons>
                        <navigation />
                        <pagination />
                    </template>
                </carousel>
            </div>
        </section>

        <section class="howitwork">
            <h1 class="howittitle">How It works</h1>
            <div class="how-it-work">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="how-work">
                                <div class="numb-circle">
                                    <div class="inner-circle">
                                        1
                                    </div>
                                </div>
                                <div class="inner-box-how">
                                    <div class="icon-line">
                                        <img src="../assets/images/bathtub.svg" alt="" style="max-width: 70px;">
                                    </div>
                                    <h3 class="mont-500">Choose bathroom</h3>
                                    <p class="mont-400">Lorem ipsum dolor sit amet consectetur adipisicing elit</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="how-work">
                                <div class="numb-circle">
                                    <div class="inner-circle">
                                        2
                                    </div>
                                </div>
                                <div class="inner-box-how">
                                    <div class="icon-line">
                                        <img src="../assets/images/questions.svg" alt="" style="max-width: 70px;">
                                    </div>
                                    <h3 class="mont-500">Answer Quick q's</h3>
                                    <p class="mont-400">Lorem ipsum dolor sit amet consectetur adipisicing elit</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="how-work">
                                <div class="numb-circle">
                                    <div class="inner-circle">
                                        3
                                    </div>
                                </div>
                                <div class="inner-box-how">
                                    <div class="icon-line">
                                        <img src="../assets/images/Shape@3x.png" alt="" style="max-width: 70px;">
                                    </div>
                                    <h3 class="mont-500">Choose bathroom</h3>
                                    <p class="mont-400">Lorem ipsum dolor sit amet consectetur adipisicing elit</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="customerreview">
            <div class="row">
                <div class="col-12">
                    <h3 class="customerreviewtitle">Customer review</h3>
                </div>
            </div>
            <carousel  :autoplay="9000" :wrap-around="true">
                <slide class="">
                    <div class="row">
                        <div class="col-12 col-md-6 my-5">
                            <img src="../assets/images/162756.png" alt="" class="customer-image">
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="cust-text">
                                <div class="inner-cust-text">
                                    <img src="../assets/images/quote.svg" alt="" class="quote-svg">
                                    <p class="mont-500 review-text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam velit quasi, quam ad numquam, incidunt consectetur voluptatem labore.</p>
                                    <p class="mont-700 customer-name">
                                        Alvin Simons1
                                    </p>
                                    <div class="star-move">
                                        <div class="star-rating">
                                            <img src="../assets/images/Star@3x.png" alt="">
                                            <img src="../assets/images/Star@3x.png" alt="">
                                            <img src="../assets/images/Star@3x.png" alt="">
                                            <img src="../assets/images/Star@3x.png" alt="">
                                            <img src="../assets/images/Star@3x.png" alt="">
                                        </div>
                                        <!-- <div class="arrow-set">
                                            <img src="../assets/images/Arrow-left.svg" alt="">
                                            <img src="../assets/images/Arrow-right.svg" alt="">
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>
                <slide class="">
                    <div class="row">
                        <div class="col-12 col-md-6 my-5">
                            <img src="../assets/images/162756.png" alt="" class="customer-image">
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="cust-text">
                                <div class="inner-cust-text">
                                    <img src="../assets/images/quote.svg" alt="" class="quote-svg">
                                    <p class="mont-500 review-text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam velit quasi, quam ad numquam, incidunt consectetur voluptatem labore.</p>
                                    <p class="mont-700 customer-name">
                                        Alvin Simons2
                                    </p>
                                    <div class="star-move">
                                        <div class="star-rating">
                                            <img src="../assets/images/Star@3x.png" alt="">
                                            <img src="../assets/images/Star@3x.png" alt="">
                                            <img src="../assets/images/Star@3x.png" alt="">
                                            <img src="../assets/images/Star@3x.png" alt="">
                                            <img src="../assets/images/Star@3x.png" alt="">
                                        </div>
                                        <!-- <div class="arrow-set">
                                            <img src="../assets/images/Arrow-left.svg" alt="">
                                            <img src="../assets/images/Arrow-right.svg" alt="">
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>
                <slide class="">
                    <div class="row">
                        <div class="col-12 col-md-6 my-5">
                            <img src="../assets/images/162756.png" alt="" class="customer-image">
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="cust-text">
                                <div class="inner-cust-text">
                                    <img src="../assets/images/quote.svg" alt="" class="quote-svg">
                                    <p class="mont-500 review-text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam velit quasi, quam ad numquam, incidunt consectetur voluptatem labore.</p>
                                    <p class="mont-700 customer-name">
                                        Alvin Simons3
                                    </p>
                                    <div class="star-move">
                                        <div class="star-rating">
                                            <img src="../assets/images/Star@3x.png" alt="">
                                            <img src="../assets/images/Star@3x.png" alt="">
                                            <img src="../assets/images/Star@3x.png" alt="">
                                            <img src="../assets/images/Star@3x.png" alt="">
                                            <img src="../assets/images/Star@3x.png" alt="">
                                        </div>
                                        <!-- <div class="arrow-set">
                                            <img src="../assets/images/Arrow-left.svg" alt="">
                                            <img src="../assets/images/Arrow-right.svg" alt="">
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>
                <template #addons>
                        <navigation />
                        <pagination />
                </template>
            </carousel>
        </div>
        <div class="our-bathle">
            <div class="our-bathle-inner">
                <div class="container our-bathle-margin-top">
                    <div class="row">
                        <div class="col-12 col-md-8 mx-auto">
                            <h1 class="comforta-700 my-3 text-center text-white">Our bathle's</h1>
                            <p class="ourparagraph">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</p>
                        </div>
                        <div class="col-12">
                            <div class="our-bathle-tick">
                                <div class="one-text">
                                    <img src="../assets/images/tick.svg" alt=""> 
                                    <span class="bathle-approved-ac"> Bathle Approved & accredited</span>
                                </div>
                                <div class="one-text">
                                    <img src="../assets/images/tick.svg" alt=""> 
                                    <span class="bathle-approved-ac"> Combined 400 Years Experince</span>
                                </div>
                                <div class="one-text">
                                    <img src="../assets/images/tick.svg" alt=""> 
                                    <span class="bathle-approved-ac"> Friendly locals</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container minus-margin">
            <div class="row">
                <div class="col-12 col-md-8 mx-auto">
                    <h4 class="text-center mont-500 text-white margin-botom-61 header-copy ">how we stack up with competitors</h4>
                    <div class="table-boxe">
                        <div class="table-text-row">
                            <div class="table-txt mont-700"></div>
                            <div class="table-txt mont-700">
                                <img src="../assets/images/smalllogo.png" alt="">
                            </div>
                            <div class="table-txt mont-700">man in a van</div>
                            <div class="table-txt mont-700">high store</div>
                        </div>
                        <div class="table-text-row">
                            <div class="label-table mont-300    ">Label</div>
                            <div><img src="../assets/images/180758.png" alt="" class="tick-cross"></div>
                            <div><img src="../assets/images/180821.png" alt="" class="tick-cross"></div>
                            <div><img src="../assets/images/180821.png" alt="" class="tick-cross"></div>
                        </div>
                        <div class="table-text-row">
                            <div class="label-table mont-300    ">Label</div>
                            <div><img src="../assets/images/180758.png" alt="" class="tick-cross"></div>
                            <div><img src="../assets/images/180821.png" alt="" class="tick-cross"></div>
                            <div><img src="../assets/images/180821.png" alt="" class="tick-cross"></div>
                        </div>
                        <div class="table-text-row">
                            <div class="label-table mont-300    ">Label</div>
                            <div><img src="../assets/images/180758.png" alt="" class="tick-cross"></div>
                            <div><img src="../assets/images/180821.png" alt="" class="tick-cross"></div>
                            <div><img src="../assets/images/180821.png" alt="" class="tick-cross"></div>
                        </div>
                        <div class="table-text-row">
                            <div class="label-table mont-300    ">Label</div>
                            <div><img src="../assets/images/180758.png" alt="" class="tick-cross"></div>
                            <div><img src="../assets/images/180821.png" alt="" class="tick-cross"></div>
                            <div><img src="../assets/images/180821.png" alt="" class="tick-cross"></div>
                        </div>
                        <div class="table-text-row">
                            <div class="label-table mont-300    ">Label</div>
                            <div><img src="../assets/images/180758.png" alt="" class="tick-cross"></div>
                            <div><img src="../assets/images/180821.png" alt="" class="tick-cross"></div>
                            <div><img src="../assets/images/180821.png" alt="" class="tick-cross"></div>
                        </div>
                        <div class="table-text-row">
                            <div class="label-table mont-300    ">Label</div>
                            <div><img src="../assets/images/180758.png" alt="" class="tick-cross"></div>
                            <div><img src="../assets/images/180821.png" alt="" class="tick-cross"></div>
                            <div><img src="../assets/images/180821.png" alt="" class="tick-cross"></div>
                        </div>
                        <div class="text-center">
                            <router-link class="green-btn getquotbtn" to="/Qualifyingprocess">
                                GET A QUOTE
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container py-5 ourpromise-full">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="Our-promise">
                        <h1 class="header">Our price promise</h1>
                        <p class="paragraph">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed similique tempora deleniti necessitatibus ullam harum </p>
                        <div class="quick-bullet">
                            <div class="quick-internal">
                                <img src="../assets/images/Check@3x.png" alt="" class="atoms-colors-gray-1">
                                <span class="something-goes-here">Quick bullet Point</span>
                            </div>
                            <div class="quick-internal">
                                <img src="../assets/images/Check@3x.png" alt="" class="atoms-colors-gray-1">
                                <span class="something-goes-here">Quick bullet Point</span>
                            </div>
                            <div class="quick-internal">
                                <img src="../assets/images/Check@3x.png" alt="" class="atoms-colors-gray-1">
                                <span class="something-goes-here">Quick bullet Point</span>
                            </div>
                            <a href="" class="green-btn my-5 button-molecules-buttons-prima">FIND OUT MORE</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <img src="../assets/images/insurance.svg" alt="" width="100%" class="insurance mx-auto">
                </div>
            </div>
        </div>
        <div class="container faq-question-full">
            <div class="row">
                <div class="col-12 col-md-8 mx-auto">
                    <h1 class="frequently-asked-questions">Frequently asked questions</h1>
                    <div class="bs-example">
                        <div class="panel-group" id="accordion">
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne"><span class="glyphicon glyphicon-plus"></span> What service areas do you cover?</a>
                                    </h4>
                                </div>
                                <div id="collapseOne" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat m dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwo"><span class="glyphicon glyphicon-plus"></span> FAQ Question?</a>
                                    </h4>
                                </div>
                                <div id="collapseTwo" class="panel-collapse collapse in">
                                    <div class="panel-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat m dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapseThree"><span class="glyphicon glyphicon-plus"></span> FAQ Question?</a>
                                    </h4>
                                </div>
                                <div id="collapseThree" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat m dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapsefour"><span class="glyphicon glyphicon-plus"></span> FAQ Question?</a>
                                    </h4>
                                </div>
                                <div id="collapsefour" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat m dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapsefive"><span class="glyphicon glyphicon-plus"></span> FAQ Question?</a>
                                    </h4>
                                </div>
                                <div id="collapsefive" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                            consequat m dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor inc.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row mb-5">
                <div class="col-12">
                    <h1 class="header text-center">Our Guides</h1>
                </div>
                <div class="col-12 col-md-4 my-3 mb-5">
                    <div class="our-guides mb-5">
                        <img src="../assets/images/162756.png" alt="">
                        <p class="date">22 Jan 2021</p>
                        <p class="post-title">How to run your bathroom project without hassle</p>
                        <a href="" class="read-more">READ MORE</a>
                    </div>
                </div>
                <div class="col-12 col-md-4 my-3 mb-5">
                    <div class="our-guides mb-5">
                        <img src="../assets/images/162756.png" alt="">
                        <p class="date">22 Jan 2021</p>
                        <p class="post-title">How to run your bathroom project without hassle</p>
                        <a href="" class="read-more">READ MORE</a>
                    </div>
                </div>
                <div class="col-12 col-md-4 my-3 mb-5">
                    <div class="our-guides mb-5">
                        <img src="../assets/images/162756.png" alt="">
                        <p class="date">22 Jan 2021</p>
                        <p class="post-title">How to run your bathroom project without hassle</p>
                        <a href="" class="read-more">READ MORE</a>
                    </div>
                </div>
            </div>
        </div>
    </main>

    

</template>
<script>

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import bathleApiService  from '@/core/services/bathleApiService.js'

export default{
    name:"home-cmp",
    components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1,
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 3,
      },
      // 1024 and up
      1024: {
        itemsToShow: 5,
      },
    },
  }),

  

}
console.log(bathleApiService.ProductSearch('Cavally',1))
</script>
