<template>
    <div v-if="tempateId==1">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="full-height">
                        <div class="question-box">
                            <h2 class="header text-center"> Have you purchased your bathroom suite?</h2>
                            <div class="yex-no-box">
                                <div class="yes-box" v-on:click="BathroomFrom">
                                    <img src="../assets/images/icons/check2.svg" alt="">
                                    <h3 class="mont">Yes</h3>
                                </div>
                                <router-link class="yes-box" to="/reminder">
                                    <img src="../assets/images/icons/cancel.svg" alt="">
                                    <h3 class="mont">No</h3>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="tempateId==2">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="full-height">
                        <div class="question-box">
                            <h2 class="header text-center"> Where did you buy your bathroom from?</h2>
                            <div class="yex-no-box">
                                <div class="logo-boxxxe" v-on:click="PostalCode(1)">
                                    <img src="../assets/images/logo/Card(1).svg" alt="">
                                </div>
                                <div class="logo-boxxxe" v-on:click="PostalCode(2)">
                                    <img src="../assets/images/logo/Card(2).svg" alt="">
                                </div>
                                <div class="logo-boxxxe" v-on:click="PostalCode(3)">
                                    <img src="../assets/images/logo/Card-1(1).svg" alt="">
                                </div>
                                <div class="logo-boxxxe" v-on:click="PostalCode(4)">
                                    <img src="../assets/images/logo/Card-1(2).svg" alt="">
                                </div>
                                <div class="logo-boxxxe" v-on:click="PostalCode(5)">
                                    <img src="../assets/images/logo/Card-1.svg" alt="">
                                </div>
                                <div class="logo-boxxxe" v-on:click="PostalCode(6)">
                                    <img src="../assets/images/logo/Card.svg" alt="">
                                </div>
                            </div>
                            <div class="yex-no-box">
                                <router-link class="logo-boxxxe not purchased" to="/reminder">
                                    <h4 class="mont-600">Not purchased yet</h4>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="tempateId==3">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="full-height">
                        <div class="div-contentt">
                            <h2 class="header text-center">Enter your postcode</h2>
                            <div class="detail-boxex">
                                <p class="we-use-this-to-assig">We use this to assign you a local Bathle approved installer</p>
                                <div class="input-divi">
                                    <input type="text" placeholder="eg BS1 2DA">
                                </div>
                                <div class="input-divi" v-on:click="InstallationWeak">
                                    <button class="detail-continue" type="submit">CHECK FOR LOCAL INSTALLER</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="tempateId==4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="full-height">
                        <div class="question-box">
                            <h2 class="header text-center">Are you looking for installation within 12 weeks?</h2>
                            <div class="yex-no-box">
                                <div class="yes-box" v-on:click="Budget">
                                    <img src="../assets/images/icons/check2.svg" alt="">
                                    <h3 class="mont">Yes</h3>
                                </div>
                                <router-link class="yes-box" to="/fullybooked">
                                    <img src="../assets/images/icons/cancel.svg" alt="">
                                    <h3 class="mont">No</h3>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="tempateId==5">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="full-height">
                        <div class="question-box">
                            <h2 class="header text-center">Is your installation budget between £2,500 – £5,000?</h2>
                            <div class="yex-no-box">
                                <router-link class="yes-box" to="/yourdetails" >
                                    <img src="../assets/images/icons/check2.svg" alt="">
                                    <h3 class="mont">Yes</h3>
                                </router-link>
                                <router-link class="yes-box" to="/fullybooked">
                                    <img src="../assets/images/icons/cancel.svg" alt="">
                                    <h3 class="mont">No</h3>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { inject } from 'vue'
export default{
    name:"qualifying-questions",
    setup()
    {
        const cryoptojs = inject('cryptojs');
        return {
      cryoptojs
    }
    },
    data(){
        return {
        tempateId:1,
        supplier_id:0
        }
    },
    created:function()
    {
    localStorage.removeItem( process.env.VUE_APP_QUOTE_ID);
	localStorage.removeItem(process.env.VUE_APP_SUPPLIER_KEY);
    }, 
    methods:{
        BathroomSuit:function()
        {
          this.tempateId=1;
        },
        BathroomFrom:function()
        {
          this.tempateId=2;
        },
        PostalCode:function(supplier_id)
        {
            console.log(process.env.VUE_APP_CRYPTO_KEY);
            this.supplier_id=this.cryoptojs.AES.encrypt(supplier_id.toString(),process.env.VUE_APP_CRYPTO_KEY).toString();
            localStorage.setItem(process.env.VUE_APP_SUPPLIER_KEY,this.supplier_id);
            this.tempateId=3;
        },
        InstallationWeak:function()
        {
            this.tempateId=4;
        },
        Budget:function()
        {
            this.tempateId=5;
        }
    }
}
</script>